

























import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'

import {
  DynamicHelperMixin
} from '../../../front/shared/mixins/dynamicHelper.mixin'
import { Loader } from '../../../front/shared/molecules/Loader'
import { ProductCard } from '../../../front/products/organisms/ProductCard'
import { StructureConfigurable } from '../../../support/mixins'
import { SuggestedProductsMixin } from '../../../front/shared/mixins/suggestedProducts.mixin'
import {
  translateProductToProductCard
} from '../../../front/products/organisms/ProductCard/ProductCard.helpers'

import { AbstractModuleUi } from '../../abstract/ui'

import {
  GLOBAL_CONTAINER_WIDTH,
  PRODUCTS_TEASER_COMPONENT_KEY,
  PRODUCTS_TEASER_DEFAULT_CONFIG
} from '../ProductsTeaser.config'
import { ProductsTeaserModule, ProductTeaserSourceMode } from '../ProductsTeaser.contracts'
import { productsTeaserContentFactory } from '../ProductsTeaser.factory'

/**
 * Container component for the `ProductsTeaserModuleUi`.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ProductsTeaserModuleUi>({
  name: 'ProductsTeaserModuleUi',
  components: { Loader, ProductCard },
  async created (): Promise<void> {
    this.config = this.getComponentConfig(
      PRODUCTS_TEASER_COMPONENT_KEY,
      PRODUCTS_TEASER_DEFAULT_CONFIG
    )

    if (this.isMobile()) {
      this.defaultWidth = `${(100 / this.productsPerPage.mobile)}vw`
    } else {
      this.defaultWidth = `${(100 / this.productsPerPage.desktop)}%`
    }
  },
  async mounted () {
    if (this.automationMode && this.automationMode === ProductTeaserSourceMode.Synerise) {
      if (this.shouldFetchSuggestedProducts && this.shouldFetchSyneriseProducts) {
        const sku = this.content.sku
        await this.loadSuggestedProducts(
          sku,
          this.automationMode,
          this.content.syneriseUids,
          this.content.useMissingDeliveryValueForSynerise
        )
      }
    } else {
      if (this.shouldFetchSuggestedProducts) {
        await this.loadSuggestedProducts(this.content.sku, this.automationMode)
      }
    }
  },
  updated () {
    this.initDynamicEventsData()
  }
})
export class ProductsTeaserModuleUi extends Mixins<AbstractModuleUi<ProductsTeaserModule>, StructureConfigurable, SuggestedProductsMixin, DynamicHelperMixin>(
  AbstractModuleUi,
  StructureConfigurable,
  SuggestedProductsMixin,
  DynamicHelperMixin
) {
  protected defaultContent = productsTeaserContentFactory()

  public defaultWidth = 'auto'
  public suggestedProductsLoaded = false

  @Ref('productsTeaser')
  public readonly productsTeaserRef!: HTMLElement | null

  @Watch('cart')
  protected async onCartChange () {
    await this.tryLoadingSuggestedProductsByFreeDelivery()
  }

  /**
   * Defines typeof automation
   */
  public get automationMode (): string | undefined {
    if (this.data.source && this.data.source.options?.mode) {
      return this.data.source.options.mode
    }

    return undefined
  }

  public get carouselConfig () {
    return {
      ...this.config.carouselConfig,
      responsive: {
        ...this.config.carouselConfig.responsive,
        desktop: {
          ...this.config.carouselConfig.responsive.desktop,
          perPage: this.productsPerPage.desktop
        }
      }
    }
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.content.customClass || this.content.customClass.length === 0) {
      return []
    }
    return this.content.customClass
  }

  /**
   * Products teaser heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Products teaser content
   */
  public get products () {
    if (this.shouldFetchSuggestedProducts && this.suggestedProducts && this.suggestedProducts.length > 0) {
      return this.suggestedProducts
    }

    if (!this.content.products || this.content.products.length === 0) {
      return
    }

    return this.content.products.map(p => translateProductToProductCard(p))
  }

  /**
   * Determines amount of products per carousel page
   */
  public get productsPerPage () {
    return {
      mobile: this.config.carouselConfig.responsive.mobile.perPage,
      desktop: this.content.productsPerPage ??
        this.config.carouselConfig.responsive.desktop.perPage
    }
  }

  /**
   * Determines product card image width based on (container width / slides amount).
   */
  public get productCardImageWidth (): number {
    const device = this.isMobile() ? 'mobile' : 'desktop'
    return (GLOBAL_CONTAINER_WIDTH[device] / this.productsPerPage[device])
  }

  /**
   * Determines whether products in carousel should be automatically fetched (recommendations)
   */
  public get shouldFetchSuggestedProducts (): boolean {
    return !!this.data.source
  }

  public get shouldFetchSyneriseProducts (): boolean {
    return !!(this.data.source && this.data.source.options?.mode === ProductTeaserSourceMode.Synerise)
  }

  public get loadSuggestedProductsByDelivery (): boolean {
    return this.getConfigProperty('loadSuggestedProductsByDelivery')
  }

  public get shouldSendDynamicContentEvents (): boolean {
    return this.getConfigProperty('shouldSendDynamicContentEvents')
  }

  /**
   * Composes a content for dynamic data events
   * @see DynamicContent
   */
  public initDynamicEventsData (): void {
    if (!this.shouldSendDynamicContentEvents) {
      return
    }

    this.composeDynamicContentData(
      this.content.heading ? this.content.heading.content : '',
      null,
      this.content.syneriseUids ?? ''
    )

    this.handleContentShow(this.productsTeaserRef)
  }

  /**
   * Tries to load suggested products only once, when the cart exists
   * @protected
   */
  protected async tryLoadingSuggestedProductsByFreeDelivery (): Promise<void> {
    if (this.cart && this.shouldFetchSyneriseProducts && this.content.useMissingDeliveryValueForSynerise && !this.suggestedProductsLoaded) {
      const sku = this.content.sku
      await this.loadSuggestedProducts(
        sku,
        this.automationMode,
        this.content.syneriseUids,
        this.content.useMissingDeliveryValueForSynerise
      )
      this.suggestedProductsLoaded = true
    }
  }
}

export default ProductsTeaserModuleUi
